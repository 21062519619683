import React from 'react';
import { Box } from '@chakra-ui/react';

interface SectionCardProps {
  children: React.ReactNode;
}

function SectionCard({ children }: SectionCardProps) {
  return (
    <Box
      my={4}
      p={5}
      bg="#fff"
      boxShadow="0 1px 4px 0 rgb(0 0 0 / 14%)"
      rounded="lg"
      background="linear-gradient(180deg, #FFFFFE 0%, #FFFCFC 100%)"
    >
      {children}
    </Box>
  );
}

export default SectionCard;
