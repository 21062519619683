import bistro from './Bistro.json';
import nfts from './NFTs.json';
import erc20 from './ERC20.json';
import {
  getBistroContractAddr,
  getTokenContract,
  getNFTAddr,
  getContract,
  callContract,
  writeContract,
  getCurrentAccountAddr,
} from '../../utils/web3';

export const bistroApi = () => {
  const contractAddress = getBistroContractAddr();
  const contract = getContract(contractAddress, bistro.abi);

  const getPoolIds = async () => {
    const res = await callContract(contract, 'poolIds', []);
    return res;
  };
  const getPool = async (id: number) => {
    const res = await callContract(contract, 'getPool', [id]);
    return res;
  };

  const getFaceValue = async (poolId: number, nftAddress: string, id: number) => {
    const res = await callContract(contract, 'getFaceValue', [poolId, nftAddress, id]);
    return res;
  };

  const getPoolsBalance = async (id: number) => {
    const res = await callContract(contract, 'getPoolsBalance', [id]);
    return res;
  };

  //
  const claim = async (poolId: number, nftAddress: string, nftId: string, cbs: any) => {
    const res = await writeContract(contract, 'claim', [poolId, nftAddress, nftId], cbs);
    return res;
  };

  return {
    getPoolIds,
    getPool,
    getFaceValue,
    getPoolsBalance,
    claim,
  };
};

// ERC1155 API
export const nftsApi = () => {
  const contractAddress = getNFTAddr();
  const contract = getContract(contractAddress, nfts.abi);

  // query 1155 token
  const getCardBal = async (tokenId: number, addr: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'balanceOf', [address, tokenId]);
    return res;
  };
  // query 1155 token
  const getCardLimt = async (tokenId: number) => {
    const res = await callContract(contract, 'maxSupply', [tokenId]);
    return res;
  };
  // query 1155 token total supply
  const getCardTotalSupply = async (tokenId: number) => {
    const res = await callContract(contract, 'totalSupply', [tokenId]);
    return res;
  };

  // query approve from contract
  const isApprovedForAll = async (contractAddr: string, addr?: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'isApprovedForAll', [address, contractAddr]);
    return res;
  };

  // ===== write ======

  // approve from contract
  const setApprovalForAll = async (contractAddr: string, cbs: any) => {
    const res = await writeContract(contract, 'setApprovalForAll', [contractAddr, true], cbs);
    return res;
  };

  return {
    ...contract,
    getCardBal,
    getCardLimt,
    getCardTotalSupply,
    isApprovedForAll,
    setApprovalForAll,
  };
};

export default {};
