/* eslint-disable */
export const NAV_MAP: Record<string, string> = {
  // 'nav.home': '/',
};

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_TESTNET = 97,
  XDAI = 100,
}

export const SupportedChainId = [100, 4]; // xdai and rinkeby

export const BISTRO: any = {
  [ChainId.XDAI]: '0x8EacB8822fA636b5BB37612C202bA4B0fb199b7D',
  [ChainId.RINKEBY]: '0x60e52D788F2c6a0f124416612112daAa0fdC8E34',
};

export const NFTS: any = {
  [ChainId.XDAI]: '0x5e2Ed8c1FaE87F94EC1827517952a861eF111E70',
  [ChainId.RINKEBY]: '0x295F2515693d7cdCDbb6Ea1b3Cb116a4D91be38c',
};

export const TOKEN_ADDRESS: { [key: string]: any } = {};

export const TOKEN: any = {
  [ChainId.XDAI]: '',
  [ChainId.RINKEBY]: '0xd996D952e703d715D5fACB05915817CD53F377A5',
};

export const WXDAI: any = {
  [ChainId.XDAI]: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

export const RICE: any = {
  [ChainId.XDAI]: '0x97Edc0e345FbBBd8460847Fcfa3bc2a13bF8641F',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

export const poolConfig = {
  0: {
    token: RICE,
    tokenName: 'RICE',
  },
  1: {
    token: WXDAI,
    tokenName: 'WxDAI',
  },
};

export const POINT_PRECISION = 100000000;
export const TOKEN_PERCISION = 1000000000000000000;

// switch network
export const CHAIN_CONFIGS = {
  100: {
    id: '1',
    jsonrpc: '2.0',
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x64',
        chainName: 'xDai',
        rpcUrls: ['https://dai.poa.network'],
        blockExplorerUrls: ['https://blockscout.com/poa/xdai'],
        nativeCurrency: {
          name: 'xDai',
          symbol: 'XDAI',
          decimals: 18,
        },
      },
    ],
  },
  // 4: {
  //   id: '1',
  //   jsonrpc: '2.0',
  //   method: 'wallet_addEthereumChain',
  //   params: [
  //     {
  //       chainId: '0x4',
  //       chainName: 'Rinkeby',
  //       rpcUrls: ['https://rinkeby.infura.io/v3/6c3a011449f34ccdaaaddfc504d2e1e1'],
  //       blockExplorerUrls: ['https://rinkeby.etherscan.io'],
  //       nativeCurrency: {
  //         name: 'Rinkeby',
  //         symbol: 'ETH',
  //         decimals: 18,
  //       },
  //     },
  //   ],
  // },
  4: {
    id: '1',
    jsonrpc: '2.0',
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: '0x4' }],
  },
};
