import React, { useState, useEffect } from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  InputGroup,
  InputRightElement,
  useMediaQuery,
  Center,
  Image,
  Button,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import NumberFormat from 'react-number-format';
import ReactGA from 'react-ga';

import { Formik } from 'formik';
import { InputControl, SubmitButton } from 'formik-chakra-ui';

import { getAddrState, useUserNFT } from '../../api/query';
import { bistroApi } from '../../api/contracts';
import ApproveBtn from '../ApproveBtn';
import { toast, formatBalance, timeformater } from '../../utils';

import { ToastProps } from '../../utils/types';
import { t } from '../../i18n';
import rice from '../../assets/rice.png';
import wxdai from '../../assets/xdai.svg';
import { getBistroContractAddr, getNFTAddr } from '../../utils/web3';

const iconMap = {
  RICE: rice,
  WXDAI: wxdai,
};

interface FormValues {
  amount: string;
}

const ClaimModal = (props: any) => {
  const [isBase, isXL] = useMediaQuery(['(max-width: 768px)', '(min-width: 1280px)']);
  const address = getAddrState();

  const { refetch: refetchNFTs } = useUserNFT(address);
  const { dataObj, cb = null, isOpen, onClose } = props;
  const { value, tokenName, image, cardId, poolId, expiredAt = 0 } = dataObj;
  const initialValues: FormValues = { amount: value };
  const [loading, setLoading] = useState(false);

  const btnStyle = {
    size: 'lg',
    mt: { base: 4, md: 5 },
    width: '100%',
    fontSize: '20px',
    fontWeight: 'bold',
  };

  // nft claim
  const onClaim = async () => {
    const bistro = bistroApi();
    ReactGA.event({
      category: `claim${poolId}`,
      action: `claim by card: ${cardId}`,
      value,
    });

    try {
      setLoading(true);

      const toastProps: ToastProps = {
        title: 'Transaction',
        desc: '',
        status: 'success',
      };
      const cbs = {
        receipt: () => {
          toastProps.desc = t(`redeem.success`);
          toast(toastProps);
          setLoading(false);

          refetchNFTs();
          onClose();
          if (cb) {
            cb();
          }
        },
        error: () => {
          setLoading(false);
          // refetchPoolInfo();
        },
      };

      await bistro.claim(poolId, getNFTAddr(), cardId, cbs);
    } catch (error) {
      console.log(error);

      ReactGA.event({
        category: `claim${poolId} error`,
        action: `claim by card: ${cardId} error`,
        value,
      });

      const toastProps = {
        title: '',
        desc: '',
        duration: 6000,
        status: 'info',
      };
      toastProps.title = 'Error';
      toastProps.desc = error.message;
      toastProps.status = 'error';
      toast(toastProps);
      setLoading(false);
    }

    return '';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify="center">
            <Text color="text" fontWeight="bold" textColor="textPrimary">
              {'Claim'}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={{ base: 4, md: 6, xl: 10 }} mt={{ base: '-20px', md: 0 }}>
          <Text>{'Burn tickets to claim tokens'}</Text>
          <Box
            h="200px"
            w="300px"
            p={2}
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.16)"
            background="#FFFCF5"
          >
            <Center
              py={2}
              h="160px"
              bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
              overflow="hidden"
            >
              <Image src={image} maxW="300px" maxH="100%" />
            </Center>
            <Flex>
              <Text fontWeight={500} lineHeight="44px">
                {value} {tokenName}
              </Text>
              {expiredAt > 0 && <Text>{timeformater(expiredAt)}</Text>}
            </Flex>
          </Box>

          <Formik
            initialValues={initialValues}
            onSubmit={onClaim}
            // validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, setFieldValue }) => {
              return (
                <Box m="30px auto" as="form" onSubmit={handleSubmit as any}>
                  <Text>{'My reward'}</Text>
                  <Box
                    height={{ base: '42px', md: '48px' }}
                    px={{ base: 1, sm: 4 }}
                    borderRadius="lg"
                    border="1px"
                    borderColor="borderColor"
                  >
                    <InputGroup py={3}>
                      <InputControl
                        inputProps={{
                          fontSize: isBase ? 16 : 18,
                          fontWeight: 'bold',
                          size: isXL ? 'lg' : 'md',
                          variant: 'unstyled',
                          placeholder: t('donate.placeholder'),
                          value,
                        }}
                        name="amount"
                      />
                      <InputRightElement pt={2}>
                        <Image
                          src={iconMap[tokenName]}
                          borderRadius="40%"
                          maxW="300px"
                          maxH="100%"
                        />
                        <Text
                          as="div"
                          mr={{ base: 6, lg: 10 }}
                          fontSize={{ base: 14, lg: 20 }}
                          color="#919191"
                          fontWeight={400}
                        >
                          {tokenName}
                        </Text>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                  <Box mt={4}>
                    {address && (
                      <ApproveBtn
                        isNFTs={true}
                        contractAddr={getBistroContractAddr()}
                        button={
                          <Button
                            w="100%"
                            isLoading={loading}
                            disabled={false}
                            onClick={() => onClaim()}
                          >
                            Claim
                          </Button>
                        }
                      />
                    )}
                  </Box>

                  {/* <ApproveBtn
                    {...btnStyle}
                    token={tokenName}
                    contractAddr={getPoolAddr()}
                    button={
                      <SubmitButton isLoading={loading} isDisabled={!!errors.amount} {...btnStyle}>
                        {t('donate').toUpperCase()}
                      </SubmitButton>
                    }
                  /> */}

                  <Text
                    mt={2}
                    mb={4}
                    fontSize={{ base: 12, md: 14 }}
                    fontWeight={400}
                    color="textLable"
                  >
                    {'NOTE: Claim need to burn your tickets.'}
                  </Text>
                </Box>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ClaimModal;
