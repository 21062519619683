import React, { useState, useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { globalStore } from 'rekv';
import { toast } from '../../utils';
import { ToastProps } from '../../utils/types';
import { nftsApi } from '../../api/contracts';
import { t } from '../../i18n';
import accountStore from '../../stores/account';

type Props = {
  token?: string;
  contractAddr: string;
  button: React.ReactNode;
  contract?: string;
  tokenName?: string;
  btnStyle?: any;
  approveMethod?: boolean;
  isNFTs?: boolean;
  cb?: any;
};

const ApproveBtn = ({
  token,
  button,
  btnStyle,
  contractAddr,
  tokenName,
  approveMethod = false,
  isNFTs = false,
  cb,
  ...rest
}: Props) => {
  const { appState } = globalStore.useState('appState');
  const { nftApproved } = accountStore.useState('nftApproved');
  const { address } = appState;
  const [loading, setLoading] = useState(false);
  // get token address and token approve\
  const [approved, setApprove] = useState(false);
  // const tokenAddress = getTokenContract(token)
  const contract = nftsApi();
  const getAllowance = async () => {
    if (!isNFTs) {
      const res = await contract.getAllowance(contractAddr);
      // console.log(res.toString())
      if (res.toString() === '0') {
        setApprove(false);
      } else {
        setApprove(true);
      }
    } else {
      const res = await contract.isApprovedForAll(contractAddr);
      // console.log(res.toString())
      if (!res || res.toString() === 'false') {
        setApprove(false);
      } else {
        setApprove(true);
      }
    }
  };

  useEffect(() => {
    if (isNFTs && nftApproved) {
      setApprove(true);
    }
  }, [isNFTs, nftApproved]);

  useEffect(() => {
    getAllowance();
  }, [address, contractAddr, token]);

  if (!token && !isNFTs) {
    return <>{button}</>;
  }

  const handleApprove = async () => {
    try {
      const toastProps: ToastProps = {
        title: t('trx.success'),
        desc: '',
        status: 'success',
      };
      setLoading(true);
      const cbs = {
        receipt: (res: any) => {
          toastProps.desc = t('approve.success');
          toast(toastProps);
          setApprove(true);
          setLoading(false);
          if (cb) {
            cb();
          }
          if (isNFTs) {
            accountStore.setState({ nftApproved: true });
          }
        },
        error: (err: any) => {
          console.log(err);
          setApprove(false);
          setLoading(false);
        },
      };
      if (isNFTs) {
        await contract.setApprovalForAll(contractAddr, cbs);
      } else {
        await contract.approve(contractAddr, cbs);
      }
    } catch (error) {
      const toastProps = {
        title: '',
        desc: '',
        duration: 6000,
        status: 'info',
      };
      toastProps.title = 'Error';
      toastProps.desc = error.message;
      toastProps.status = 'error';
      toast(toastProps);
      setLoading(false);
      setApprove(false);
      setLoading(false);
    }
    return null;
  };
  const tokenText = tokenName || token?.toUpperCase();
  const btnText = `${t('approve').toUpperCase()}  ${isNFTs ? 'NFTs' : tokenText}`;
  return (
    <>
      {approved ? (
        button
      ) : (
        <Button
          isLoading={loading}
          loadingText={t('approving')}
          onClick={handleApprove}
          {...rest}
          w="100%"
        >
          {btnText}
        </Button>
      )}
    </>
  );
};

export default ApproveBtn;
