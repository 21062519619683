import React from 'react';
import { Box, Button, Image, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getNFTAddr } from '../../utils/web3';
import { formatBalance } from '../../utils';
import { useFaceValue } from '../../api/query';

interface TicketCardProps {
  cardId: string;
  name: string;
  amount: number;
  image: string;
  description: string;
  isSelected: boolean;
  bistro: any;
  onCardSelect: (id: string, name: string, value: number) => void;
  onClick: any;
}

function TicketCard({
  cardId,
  name,
  image,
  amount,
  bistro = {},
  description,
  isSelected,
  onCardSelect,
  onClick,
}: TicketCardProps) {
  const { t } = useTranslation();
  const { poolId = -1, token = '', value = 0 } = bistro;
  if (poolId < 0) return '';
  const { data = {} } = useFaceValue(poolId, getNFTAddr(), cardId);
  // const { value = 0 } = data;

  return (
    <Box
      w={{ base: '98%', md: '49%', lg: '32%' }}
      maxW="464px"
      my={4}
      boxShadow="0 1px 4px 0 rgb(0 0 0 / 14%)"
      rounded="lg"
      bg="#fff"
      overflow="hidden"
      borderWidth="2px"
      borderStyle="solid"
      borderColor={isSelected ? 'primary' : '#fff'}
      cursor="pointer"
      onClick={() => {
        if (isSelected) onCardSelect('', '', 0);
        else onCardSelect(cardId, name, value);

        if (onClick) {
          onClick(bistro, { cardId, name, image }, data);
        }
      }}
    >
      <Center h="160px" bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)" overflow="hidden">
        <Image src={image} maxW="300px" maxH="100%" />
      </Center>

      <Box p={2} pb={0} position="relative">
        <Text
          fontWeight="bold"
          mb={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {value} {token}
        </Text>
        <Box position="absolute" right={2} top={3}>
          <Text fontSize={12} textColor="textTips" textAlign="right">
            {t('balance')}: {amount}
          </Text>
          {/* <Text fontSize={10} textColor="textTips" textAlign="right">
            {t('value')}: {formatBalance(value)}
          </Text> */}
        </Box>

        {/* <Button
          w="100%"
          mt={2}
          onClick={() => {
            if (isSelected) onCardSelect('', '', 0);
            else onCardSelect(cardId, name, value);
          }}
          className={isSelected ? 'selected' : ''}
        >
          {isSelected ? 'Deselect' : 'Select'}
        </Button> */}
      </Box>
    </Box>
  );
}

export default TicketCard;
