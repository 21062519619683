import React from 'react';
import { Center, Text } from '@chakra-ui/react';
import { t } from '../../i18n';

const ConnectTip = () => {
  return (
    <Center>
      <Text
        fontSize={{ base: '18px', md: '24px', lg: '28px', xl: '32px' }}
        textColor="#ccc"
        fontWeight="bold"
        my={4}
      >
        PLEASE CONNECT YOUR WALLET
      </Text>
    </Center>
  );
};

export default ConnectTip;
