import React from 'react';
import { Box } from '@chakra-ui/react';

const Page = () => {
  return (
    <Box textAlign="center" h="240px" lineHeight="240px">
      404
    </Box>
  );
};

export default Page;
