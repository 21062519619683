import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

// import { groupBy } from 'ramda';
// import { Container, Button, Center, Stack, StackDivider } from '@chakra-ui/react';
// import { useTranslation } from 'react-i18next';
// import { useQuery } from 'react-query';
// import { globalStore } from 'rekv';
import { useHistory, useLocation } from 'react-router-dom';
import CommLayout from '../../layouts/common';
// import {

// } from '../../api/contracts';
// import { useAssetsQuery } from '../../api/query';

const Page = () => {
  const history = useHistory();
  const location = useLocation();
  const ref = new URLSearchParams(location.search).get('ref');

  useEffect(() => {
    if (ref) {
      ReactGA.event({
        category: `Refer stats`,
        action: `Refer page open with ${ref}`,
      });
    }
    history.push('/claim/0');
  }, []);
  return <CommLayout title="title.home">Home</CommLayout>;
};

export default Page;
