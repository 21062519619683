import React, { useEffect } from 'react';
import { Box, Flex, Image, Link } from '@chakra-ui/react';
import { globalStore } from 'rekv';
import { useHistory } from 'react-router-dom';
import Web3Com from '../web3';
import logo from '../../assets/logo.svg';
// import dkp from '../../assets/dkp.svg';

const Header = () => {
  const history = useHistory();
  const { account, api } = globalStore.useState('account', 'api');
  useEffect(() => {
    return () => {
      // cleanup
    };
  }, [account?.address, api]);

  return (
    <Box
      as="header"
      position="fixed"
      left="0"
      right="0"
      height={{ base: '60px', md: '68px' }}
      zIndex={999}
    >
      <Box h="100%" maxWidth="6xl" mx="auto" paddingX={4}>
        <Box
          w="100%"
          h="100%"
          bg="#fff"
          borderRadius="0px 0px 12px 12px"
          boxShadow=" 0px 20px 20px rgba(0, 0, 0, 0.16)"
          paddingX={4}
          position="relative"
        >
          {/* <Flex justifyContent="center" alignItems="center" h="100%">
            <Link href="https://www.dkp.land/" isExternal>
              <Image src={dkp} h={6} mr={2} />
            </Link>
            <Link href="https://www.dkp.land/" isExternal>
              <Box
                fontSize="24px"
                fontWeight="extrabold"
                color="#444"
                as="span"
                position="relative"
                top={1}
              >
                DKP
              </Box>
            </Link>
          </Flex> */}

          <Flex
            position="absolute"
            left={{ base: 2, md: 4 }}
            top={{ base: 3, md: 2 }}
            fontSize={{ base: '20px', md: '30px' }}
            fontWeight="extrabold"
            color="primary"
            cursor="pointer"
            p={1}
            onClick={() => {
              history.push('/');
            }}
          >
            <Image
              src={logo}
              w={{ base: '26px', md: '38px' }}
              h={{ base: '26px', md: '38px' }}
              mr={2}
            />
            <Box>Bistro</Box>
          </Flex>

          <Box position="absolute" right={{ base: 2, md: 4 }} top={{ base: '12px', md: '10px' }}>
            <Web3Com />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
