import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Flex, Image, useDisclosure } from '@chakra-ui/react';
import ReactGA from 'react-ga';
import { usePoolInfo, getAddrState, useUserNFT } from '../../api/query';
import CommLayout from '../../layouts/common';
import { formatBalance, timeformater, toast } from '../../utils';
import { poolConfig } from '../../config/constants';
import ClaimCard from '../../components/claimCard';
import Tickets from '../../components/tickets';
import SectionCard from '../../components/sectionCard';
import SectionTitle from '../../components/sectionTitle';
import ConnectTip from '../../components/connectTip';
import { bistroApi } from '../../api/contracts';
import { ToastProps } from '../../utils/types';
import { t } from '../../i18n';
import { getNFTAddr } from '../../utils/web3';
import rice from '../../assets/rice.png';
import wxdai from '../../assets/xdai.svg';
import usdt from '../../assets/usdt.svg';
import diamond from '../../assets/diamond.svg';
import ClaimModal from '../../components/claimModal';

const iconMap = {
  0: rice,
  1: wxdai,
};

const Page = () => {
  // const { poolId } = useParams();
  const address = getAddrState();

  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState({});

  // const { data: poolData = {}, refetch: refetchPoolInfo } = usePoolInfo(poolId);
  // const { balance, periodEnd, periodStart, supportToken } = poolData;
  const { refetch: refetchNFTs } = useUserNFT(address);

  const [curCardId, setCurCardId] = useState('');
  const [curCardName, setCurCardName] = useState('');
  const [curValue, setCurValue] = useState(0);
  // const config = poolConfig[poolId] || { tokenName: '--' };

  const { isOpen, onOpen, onClose } = useDisclosure();

  // useEffect(() => {
  //   ReactGA.pageview(`claim page ${poolId}`);
  // }, []);

  useEffect(() => {
    // refetchPoolInfo();
  }, [address]);
  // nft select
  const onCardSelect = (cardId: string, name: string, value: number) => {
    setCurCardId(cardId);
    setCurCardName(name);
    setCurValue(value ? formatBalance(value) : value);
  };

  const onClick = (bistro = '', card = '', data = '') => {
    console.log(bistro);
    console.log(card);
    console.log(data);

    setObj({
      tokenName: bistro.token,
      value: bistro.value,
      image: card.image,
      cardId: card.cardId,
      poolId: bistro.poolId,
    });
    onOpen();
  };

  return (
    <CommLayout title="title.home">
      <SectionCard>
        <>
          <SectionTitle
            title="My Tickets"
            subTitle="Select one ticket to claim your token rewards"
          />
          <Box p={4} borderRadius="16px" boxShadow="inset 0 0 10px #707070">
            {address && (
              <Tickets curCardId={curCardId} onCardSelect={onCardSelect} onClick={onClick} />
            )}
            {!address && (
              <Box my={4}>
                <ConnectTip />
              </Box>
            )}
          </Box>
        </>
      </SectionCard>

      <ClaimModal isOpen={isOpen} dataObj={obj} onClose={onClose} />

      {/* <Box position="relative">
        <Image src={diamond} position="absolute" top="-70px" left="50%" width="128px" ml="-64px" />
      </Box> */}

      {/* <SectionCard>
        <>
          <SectionTitle
            title="Claim"
            subTitle="Claim your token rewards if you selected one ticket in My Tickets."
          />
          <Flex justify={{ base: 'center', md: 'space-between' }} wrap="wrap" mb={2}>
            <ClaimCard
              poolId={poolId}
              tokenName={config.tokenName}
              tokenLogo={iconMap[poolId]}
              address={address}
              isLoading={loading}
              cardId={curCardId}
              startDate={periodStart ? timeformater(periodStart) : ''}
              endDate={periodEnd ? timeformater(periodEnd) : ''}
              // reward={balance ? formatBalance(balance) : ''}
              disabled={!curCardId}
              tickets={curCardName ? [curCardName] : []}
              claim={curValue || ''}
              onClaim={onClaim}
            />
            <ClaimCard
              poolId={'-1'}
              tokenName="usdt"
              tokenLogo={usdt}
              address={''}
              isLoading={false}
              cardId={''}
              startDate={''}
              endDate={''}
              // reward={balance ? formatBalance(balance) : ''}
              disabled={true}
              tickets={[]}
              claim={''}
              onClaim={onClaim}
            />
          </Flex>
        </>
      </SectionCard> */}
    </CommLayout>
  );
};

export default Page;
