import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { getAddrState, useUserNFT } from '../../api/query';
import TicketCard from '../ticketCard';

interface TicketsProps {
  onCardSelect: (id: string, name: string, value: number) => void;
  curCardId: any;
  onClick: any;
}

function Tickets({ onCardSelect, curCardId, onClick }: TicketsProps) {
  const address = getAddrState();
  const { data: nftData = {}, refetch: refetchNFTs, error } = useUserNFT(address);
  let { cards = [] } = nftData;
  cards = cards.filter((d: any) => d.amount !== 0 && d.amount !== '0');

  return (
    <Flex justify={{ base: 'center', md: 'space-between' }} wrap="wrap" mb={2} minH="232px">
      {address &&
        cards.map((card: any, i: number) => (
          <TicketCard
            onClick={onClick}
            key={i}
            {...card}
            isSelected={card.cardId === curCardId}
            onCardSelect={onCardSelect}
          />
        ))}
      <Box w={{ base: '98%', md: '49%', lg: '32%' }} maxW="464px"></Box>
    </Flex>
  );
}

export default Tickets;
