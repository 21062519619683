import React from 'react';
import { Box, Flex, Text, Center, Divider } from '@chakra-ui/react';

interface SectionTitleProps {
  title: string;
  subTitle: string;
}

function SectionTitle({ title, subTitle }: SectionTitleProps) {
  return (
    <Box my={4}>
      <Text fontSize="20px" fontWeight="bold">
        {title}
      </Text>
      <Text mt={2}>{subTitle}</Text>

      {/* <Divider borderColor="gray.400" h={1} /> */}
    </Box>
  );
}

export default SectionTitle;
