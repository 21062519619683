import {
  useQuery,
  // useMutation,
} from 'react-query';
import { globalStore } from 'rekv';
import { bistroApi, nftsApi } from '../contracts';
import { getBistroContractAddr, getNFTAddr } from '../../utils/web3';
import { formatBalance, getMetadata, getConfig, postQuery, postQueryRice } from '../../utils';

export * from './queryClient';

const POOL_QUERY = 'getPoolInfo';
const MY_FACE_VALUE_QUERY = 'getMyFaceValue';
const MY_POINTS_QUERY = 'getMyPoints';
const CARDS_QUERY_BY_POOLID = 'getCardsByPoolId';
const CARDS_QUERY_BY_INFO = 'getCardsByInfo';
const CARDS_QUERY_BY_IDS = 'getCardsByIds';
// const CARDS_QUERY_BY_REQS = 'getCardsByRequirements';
const CONFIG_QUERY = 'getConfig';
const QUERY_USER_NFT = 'getUserNFT';
const QUERY_USER_MAINNET_RICE = 'getUserMainnetRice';

export const getConnectedState = () => {
  const { appState = {} } = globalStore.useState('appState');
  const { connected = false } = appState;
  return connected;
};

export const getAddrState = () => {
  const { appState = {} } = globalStore.useState('appState');
  const { address = false } = appState;
  return address;
};

// query ovo info
export const usePoolInfo = (idx: number) => {
  // const connect = getConnectedState();
  // if (!connect) return { data: {} };
  const getPoolInfo = async () => {
    // todo add cards
    // get ovo Wxdai bal
    const bistro = bistroApi();
    const poolInfo = await bistro.getPool(idx);
    const balance = await bistro.getPoolsBalance(idx);

    return {
      ...poolInfo,
      balance,
    };
  };
  return useQuery(`${POOL_QUERY}${idx}`, getPoolInfo);
};

// query balance for current address
export const useFaceValue = (poolId: number, nftAddress: string, nftId: number) => {
  // const connect = getConnectedState();
  // if (!connect) return { data: {} };
  // const address = useAddrState()
  const getInfo = async () => {
    const bistro = bistroApi();
    const value = await bistro.getFaceValue(poolId, nftAddress, nftId);

    return { value };
  };

  return useQuery(`${MY_FACE_VALUE_QUERY}${poolId}${nftAddress}${nftId}`, getInfo);
};

export const useConfig = () => {
  const getConfigData = async () => {
    const poolConfig = await getConfig('poolConfig');
    const redeemConfig = await getConfig('redeemConfig');
    const disableCards = await getConfig('disableCards');
    return { poolConfig, redeemConfig, disableCards };
  };
  return useQuery(`${CONFIG_QUERY}`, getConfigData);
};

export const useUserNFT = (addr: string) => {
  const nfts = nftsApi();

  const getCardInfo = async (card: any) => {
    const { cardId } = card;
    const metadata = await getMetadata(cardId.toString());
    // const maxSupply = await nfts.getCardLimt(cardId);
    // const totalSupply = await nfts.getCardTotalSupply(cardId);

    return {
      ...metadata,
      // maxSupply,
      // totalSupply,
      ...card,
    };
  };
  const getUserNFT = async () => {
    if (!addr) {
      return [];
    }
    const query = `{
        cardOwners(where:{owner:"${addr.toLowerCase()}"}){
          amount
          cardId
        }
      }`;
    const { cardOwners = [] } = await postQuery(query.toString(), {});

    return cardOwners;
  };

  const getOwnedCards = async () => {
    const cards = await getUserNFT();
    const reqs = cards.map((card: any) => {
      return getCardInfo(card);
    });
    const res = await Promise.all(reqs);
    return { cards: res };
  };

  return useQuery(`${QUERY_USER_NFT}`, getOwnedCards);
};

export const useUserRiceMainnet = (addr: string) => {
  const getUserMainnetRice = async () => {
    if (!addr) {
      return [];
    }
    const query = `{
    accounts(where:{id: "${addr.toLowerCase()}"}) {
      balances {
        value
      }
    }
  }`;
    const { accounts = [] } = await postQueryRice(query.toString(), {});
    return accounts;
  };

  return useQuery(`${QUERY_USER_MAINNET_RICE}`, getUserMainnetRice);
};
