import * as React from 'react';
import { HashRouter, Switch, Route, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { globalStore } from 'rekv';
import { Box, Flex, ChakraProvider, CSSReset, useColorMode } from '@chakra-ui/react';
import ReactGA from 'react-ga';

import Home from './pages/home';
import Claim from './pages/claim';
import NotFound from './pages/404';
import Header from './components/header';
import Footer from './components/footer';
import TranslateHOC from './components/translate';
import theme from './themes';
import Fonts from './themes/fonts';
import './i18n';

import { queryClient } from './api/query';
import { scrollTo } from './utils/ui';

require('dotenv').config();

const ControlledLayout = () => {
  const location = useLocation();
  // google ga
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOLE_GA);
    ReactGA.pageview(window.location.pathname);
  }, []);
  // Scroll on top when route changes
  React.useEffect(() => {
    scrollTo(0);
  }, [location.pathname, location.search]);

  return (
    <Flex height="100%" direction="column" maxWidth="6xl" mx="auto" paddingX={4}>
      <Header />

      <Box flex="1 0 auto" overflow="hidden" mt={{ base: '72px', md: '76px' }}>
        <Box width="100%">
          <Switch>
            <Route exact strict path="/" component={Home} />
            <Route exact strict path="/claim/:poolId" component={Claim} />
            <Route exact strict path="*" component={NotFound} />
          </Switch>
          <Box flexShrink={0}></Box>
        </Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <Fonts />
    <ChakraProvider theme={theme}>
      <HashRouter>
        <TranslateHOC>
          <ControlledLayout />
        </TranslateHOC>
      </HashRouter>
    </ChakraProvider>
  </QueryClientProvider>
);

export default {};
